<template>
  <div>
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Create New Price</h4>
      </CCol>
      <CCol sm="8"> </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="12">
              <validation-observer
                ref="formValidator"
                v-slot="{ handleSubmit }"
              >
                <form
                  v-on:submit.prevent="handleSubmit(storePrice)"
                  class="p-4"
                >
                  <div v-if="serverErrors">
                    <CAlert
                      color="danger"
                      v-for="err in serverErrors"
                      :key="err"
                    >
                      {{ err }}.
                    </CAlert>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-4">
                          <ValidationProvider
                            vid="effective_from"
                            name="Effective From"
                            :rules="{
                              required: true,
                            }"
                            v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label
                                >Effective From <br />
                                <small>Select Month Year</small></label
                              >
                              <input
                                type="month"
                                :min="minMonth"
                                class="form-control"
                                v-model="price.effective_from"
                                :class="
                                  errors.length
                                    ? 'is-invalid'
                                    : ''
                                "
                              />
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4">
                          <ValidationProvider
                            vid="due_date_duration"
                            name="Due Date Duration"
                            :rules="{
                              required: true,
                              numeric: true,
                            }"
                            v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label
                                >Due Date Duration <br />
                                <small
                                  >max. days to pay bill without
                                  surcharge</small
                                ></label
                              >
                              <input
                                type="number"
                                class="form-control"
                                v-model="price.due_date_duration"
                                placeholder="Due date duration"
                                :class="
                                  errors.length
                                    ? 'is-invalid'
                                    : ''
                                "
                              />
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4">
                          <ValidationProvider
                            vid="vat"
                            name="VAT"
                            :rules="{
                              required: true,
                              regex: /^\d*\.?\d*$/,
                            }"
                            v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label
                                >VAT <br />
                                <small>(%) percent</small>
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                v-model="price.vat"
                                step="0.01"
                                :class="
                                  errors.length
                                    ? 'is-invalid'
                                    : ''
                                "
                              />
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4">
                          <ValidationProvider
                            vid="vat_reg_no"
                            name="VAT Reg. No"
                            :rules="{
                              required: true,
                              min: 6,
                            }"
                            v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>VAT Reg. No. </label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="price.vat_reg_no"
                                :class="
                                  errors.length
                                    ? 'is-invalid'
                                    : ''
                                "
                              />
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4">
                          <ValidationProvider
                            vid="service_charge"
                            name="Service Charge"
                            :rules="{
                              required: true,
                              numeric: true,
                            }"
                            v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>Service Charge </label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="price.service_charge"
                                :class="
                                  errors.length
                                    ? 'is-invalid'
                                    : ''
                                "
                              />
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label>Show Arrear on Bill?</label>
                            <div class="row">
                              <div class="checkbox col-md-4 pl-4">
                                <label>
                                  <input
                                    type="radio"
                                    name="arrear"
                                    v-model="price.show_arrear"
                                    :value="true"
                                  />
                                  Yes
                                </label>
                              </div>

                              <div class="checkbox col-md-4">
                                <label>
                                  <input
                                    type="radio"
                                    name="arrear"
                                    v-model="price.show_arrear"
                                    :value="false"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <ValidationProvider
                            vid="min_charge_type"
                            name="Min. Charge Type"
                            :rules="{
                              required: true,
                            }"
                            v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>Min. Charge Type </label>
                              <select
                                class="form-control"
                                v-model="price.min_charge_type"
                                :class="
                                  errors.length
                                    ? 'is-invalid'
                                    : ''
                                "
                              >
                                <option value="">Select One</option>
                                <option value="fixed_amount"
                                  >Fixed Amount</option
                                >
                                <option value="day_wise_unit"
                                  >Day-wise Minimum Unit</option
                                >
                                <option value="no_charge"
                                  >No Charge</option
                                >
                              </select>
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4">
                          <ValidationProvider
                            vid="min_charge_value"
                            name="Min. Charge Value"
                            :rules="{
                              required: true,
                              regex: /^\d*\.?\d*$/,
                            }"
                            v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>Min. Charge Value </label>
                              <input
                                type="number"
                                v-model="price.min_charge_value"
                                class="form-control"
                                step="0.01"
                                :class="errors.length ? 'is-invalid' : ''"
                              />
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label
                              >Send Billing Reminder before Due Date? <br
                            /></label>
                            <div class="row">
                              <div class="checkbox col-md-4 pl-4">
                                <label>
                                  <input
                                    type="radio"
                                    name="send_bill_reminder"
                                    v-model="price.send_bill_reminder"
                                    :value="true"
                                  />
                                  Yes
                                </label>
                              </div>

                              <div class="checkbox col-md-4">
                                <label>
                                  <input
                                    type="radio"
                                    name="send_bill_reminder"
                                    v-model="price.send_bill_reminder"
                                    :value="false"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4 d-none">
                          <div class="form-group">
                            <label>Send Installment Reminder?</label>
                            <div class="row">
                              <div class="checkbox col-md-4 pl-4">
                                <label>
                                  <input
                                    type="radio"
                                    name="send_installment_reminder"
                                    v-model="price.send_installment_reminder"
                                    :value="true"
                                  />
                                  Yes
                                </label>
                              </div>

                              <div class="checkbox col-md-4">
                                <label>
                                  <input
                                    type="radio"
                                    name="send_installment_reminder"
                                    v-model="price.send_installment_reminder"
                                    :value="false"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <hr />
                        </div>

                        <div class="col-md-6 pr-5">
                          <label>Billing Price Setup</label>
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Category</th>
                                <th>Unit Price (Tk)</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(val, index) in price.pricings"
                                :key="index"
                              >
                                <td>
                                  <ValidationProvider
                                    :vid="'pricings.' + index + '.category'"
                                    name="Category"
                                    :rules="{
                                      required: true,
                                    }"
                                    v-slot="{ errors }"
                                  >
                                    <select
                                      class="form-control"
                                      v-model="val.category"
                                      :class="errors.length ? 'is-invalid' : ''"
                                    >
                                      <option value="">Select Category</option>
                                      <option
                                        v-for="(newFey, fKey) in categories"
                                        :key="fKey"
                                        :value="newFey"
                                        >{{ newFey }}</option
                                      >
                                    </select>
                                    <p class="text-danger mt-0 pt-0">
                                      {{ errors[0] }}
                                    </p>
                                  </ValidationProvider>
                                </td>
                                <td>
                                  <ValidationProvider
                                    :vid="'pricings.' + index + '.unit_price'"
                                    name="Unit Price"
                                    :rules="{
                                      required: true,
                                      regex: /^\d*\.?\d*$/,
                                    }"
                                    v-slot="{ errors }"
                                  >
                                    <input
                                      type="number"
                                      v-model="val.unit_price"
                                      class="form-control"
                                      step="0.01"
                                      :class="errors.length ? 'is-invalid' : ''"
                                    />
                                    <p class="text-danger mt-0 pt-0">
                                      {{ errors[0] }}
                                    </p>
                                  </ValidationProvider>
                                </td>
                                <td>
                                  <a
                                    v-if="index > 0"
                                    href="javascript:void(0)"
                                    class="text-danger"
                                    @click="removeItem(index, 'pricing')"
                                    ><i
                                      class="fa fa-trash-o fa-lg pt-2"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    @click="addMore('pricing')"
                                  >
                                    + Add More Item
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="col-md-6 pl-5">
                          <label>Billing Surcharge Setup</label>
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Month No.</th>
                                <th>Charge (%)</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(val, index) in price.surcharges"
                                :key="index"
                              >
                                <td>
                                  <ValidationProvider
                                    :vid="'surcharges.' + index + '.month_no'"
                                    name="Month No."
                                    :rules="{
                                      required: true,
                                      numeric: true,
                                    }"
                                    v-slot="{ errors }"
                                  >
                                  <select
                                    class="form-control"
                                    v-model="val.month_no"
                                    :class="errors.length ? 'is-invalid' : ''"
                                  >
                                    <option value="">Select Month No</option>
                                    <option
                                      v-for="(newFey, fKey) in 12"
                                      :key="fKey"
                                      :value="newFey"
                                      >{{ newFey }}</option
                                    >
                                  </select>
                                  <p class="text-danger mt-0 pt-0">
                                      {{ errors[0] }}
                                    </p>
                                  </ValidationProvider>
                                </td>
                                <td>
                                  <ValidationProvider
                                    :vid="'surcharges.' + index + '.charge'"
                                    name="Charge"
                                    :rules="{
                                      required: true,
                                      regex: /^\d*\.?\d*$/,
                                    }"
                                    v-slot="{ errors }"
                                  >
                                  <input
                                    type="number"
                                    v-model="val.charge"
                                    class="form-control"
                                    step="0.01"
                                    :class="errors.length ? 'is-invalid' : ''"
                                  />
                                  <p class="text-danger mt-0 pt-0">
                                      {{ errors[0] }}
                                    </p>
                                  </ValidationProvider>
                                </td>
                                <td>
                                  <a
                                    v-if="index > 0"
                                    href="javascript:void(0)"
                                    class="text-danger"
                                    @click="removeItem(index, 'surcharge')"
                                    ><i
                                      class="fa fa-trash-o fa-lg pt-2"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    @click="addMore('surcharge')"
                                  >
                                    + Add More Item
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="col-md-12 d-none">
                          <hr />
                        </div>

                        <div class="col-md-6 pr-5 d-none">
                          <label
                            >Installments Penalty Charges
                            <small>(if overdue)</small></label
                          >
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Month No.</th>
                                <th>Charge (%)</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(val,
                                index) in price.installment_interests"
                                :key="index"
                              >
                                <td>
                                  <ValidationProvider
                                    :vid="'installment_interests.' + index + '.month_no'"
                                    name="Month No."
                                    :rules="{
                                      required: true,
                                      numeric: true,
                                    }"
                                    v-slot="{ errors }"
                                  >
                                  <select
                                    class="form-control"
                                    v-model="val.month_no"
                                    :class="errors.length ? 'is-invalid' : ''"
                                  >
                                    <option value="">Select Month No</option>
                                    <option
                                      v-for="(newFey, fKey) in 12"
                                      :key="fKey"
                                      :value="newFey"
                                      >{{ newFey }}</option
                                    >
                                  </select>
                                  <p class="text-danger mt-0 pt-0">
                                      {{ errors[0] }}
                                    </p>
                                  </ValidationProvider>
                                </td>
                                <td>
                                  <ValidationProvider
                                    :vid="'surcharges.' + index + '.charge'"
                                    name="Charge"
                                    :rules="{
                                      required: true,
                                      regex: /^\d*\.?\d*$/,
                                    }"
                                    v-slot="{ errors }"
                                  >
                                  <input
                                    type="number"
                                    v-model="val.charge"
                                    class="form-control"
                                    step="0.01"
                                    :class="errors.length ? 'is-invalid' : ''"
                                  />
                                  <p class="text-danger mt-0 pt-0">
                                      {{ errors[0] }}
                                    </p>
                                  </ValidationProvider>
                                </td>
                                <td>
                                  <a
                                    v-if="index > 0"
                                    href="javascript:void(0)"
                                    class="text-danger"
                                    @click="removeItem(index, 'installment')"
                                    ><i
                                      class="fa fa-trash-o fa-lg pt-2"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    @click="addMore('installment')"
                                  >
                                    + Add More Item
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="row">
                    <div class="col-md-12 text-right">
                      <button type="submit" class="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "CreateBillSettlementComp",
  data() {
    return {
      price: {
        effective_from: "",
        due_date_duration: 30,
        pricings: [
          { category: "Domestic", unit_price: 12.4 },
          { category: "Industrial", unit_price: 32.4 },
        ],
        surcharges: [
          { month_no: 1, charge: 5 },
          { month_no: 2, charge: 10 },
          { month_no: 3, charge: 15 },
        ],
        installment_interests: [
          { month_no: 1, charge: 5 },
          { month_no: 2, charge: 10 },
          { month_no: 3, charge: 15 },
        ],
        vat: 15,
        vat_reg_no: "",
        min_charge_type: "",
        min_charge_value: "",
        service_charge: 180,
        send_installment_reminder: true,
        send_bill_reminder: true,
        show_arrear: true,
      },
      categories: [
        "Domestic",
        "Industrial",
        "Commercial",
        "Institutional",
        "Freedom Fighter",
        "Religious Institute",
      ],
      minMonth: moment().add(1, 'months').format("YYYY-MM"),
      serverErrors: []
    };
  },
  methods: {
    addMore(type) {
      if (type === "pricing") {
        this.price.pricings.push({ category: "", unit_price: "" });
      } else if (type === "surcharge") {
        this.price.surcharges.push({ month_no: "", charge: "" });
      } else {
        this.price.installment_interests.push({ month_no: "", charge: "" });
      }
    },
    removeItem(index, type) {
      if (type === "pricing") {
        this.price.pricings.splice(index, 1);
      } else if (type === "surcharge") {
        this.price.surcharges.splice(index, 1);
      } else {
        this.price.installment_interests.splice(index, 1);
      }
    },
    storePrice() {
      this.$store.dispatch("Settings/loading", true);
      this.$store.dispatch("Pricing/store", this.price).then(
        () => {
          this.$store.dispatch("Settings/loading", false);
          this.$toastr.s("Price Successfuly Created", "Success!");
          this.$router.push("/bills/prices");
        },
        (error) => {
          this.serverErrors = [];
          if (error.response.data) {
            this.serverErrors.push(error.response.data.message);
            this.$toastr.e(error.response.data.message, "Failed!");
            if (error.response.data.errors) {
              this.$refs.formValidator.setErrors(error.response.data.errors);
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })
            }
          }
          this.$store.dispatch("Settings/loading", false);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: none;
  padding: 15px 15px 0;
  color: #000;
  label {
    font-weight: 600;
  }
}
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
}
#CAppTab {
  .nav-item {
    .nav-link {
      color: #000 !important;
      font-weight: 600;
    }
  }
}
</style>
